import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { Observable, find } from 'rxjs';
import { Store } from '@ngxs/store';
import { inject } from '@angular/core';
import { AuthSelectors } from '@app/auth/store/auth.selectors';

export const AuthInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const store = inject(Store);
  const authToken$ = store.select(AuthSelectors.getAuthToken);
  let headers = { 'Content-Type': 'application/json' };
  let AUTH_TOKEN;

  authToken$.pipe(find((token) => token !== null)).subscribe((tokenRes) => {
    AUTH_TOKEN = tokenRes;
  });

  /**
   * Any additional headers will make the API return 403
   */
  if (req.url.indexOf('/auth/') === -1) {
    // NOT found
    // Object.assign(headers, {
    //   Authorization: 'Bearer ' + localStorage.getItem(TOKEN),
    // });
  } else if (
    req.url.indexOf('/create-account/verification/') !== -1 ||
    req.url.indexOf('/create-account/pin/') !== -1 ||
    req.url.indexOf('/resend-otp') !== -1 ||
    req.url.indexOf('/activate-account') !== -1 ||
    req.url.indexOf('/create-pin') !== -1
  ) {
    // FOUND
    Object.assign(headers, {
      Authorization: 'Bearer ' + AUTH_TOKEN,
    });
  }
  req = req.clone({
    setHeaders: headers,
    setParams: {},
  });

  return next(req);
};
